import * as React from "react"
import { graphql } from 'gatsby';

import Box from '@simplate/ui/Box';
import Logo from '@simplate/ui/Logo';
import {AfterEffectsIcon} from '@simplate/ui/Icon/icons';
import TextGroup from '@simplate/ui/TextGroup';

import Accordion from 'components/Accordion'
import AccordionItem from 'components/AccordionItem'
import BetaCTA from 'components/BetaCTA'
import Button from 'components/RequestBetaButton'
import Container from 'components/Container'
import FeatureImage from 'components/FeatureImage'
import Section from 'components/Section'
import Label from 'components/Label'
import Layout from 'components/layout'
import Seo from 'components/seo'
import SplitImageView from "../components/SplitImageView";
import Text from 'components/Text'
import TitleGroup from 'components/TitleGroup'

import StackedPreviews from 'components/StackedPreviews';
import InteractiveModulesIllustration from 'components/InteractiveModulesIllustration';
import BrandkitGridIllustration from 'components/BrandkitGridIllustration';

const CreatorsPage = ({ data }) => (
  <Layout theme="light">
    <Seo 
		title="Simplate for Creators" 
		metaDescription="Simplate allows creators to produce video variations efficiently." 
	/>
    <Container marginTop={[10, 10, 12]}>
		<Section>
			<TitleGroup align="center">
				<Text as="h2" textStyle="title.lg">
					Produce hundreds of video variations.<br /> Efficiently.
				</Text>
				<Text textStyle="text.lg" color="altText">
					Turn your videos into a simple template that any client or collaborator can independently edit and export.
				</Text>
				<Button size="lg">
					<Text>Start creating for free</Text>
				</Button>
			</TitleGroup>
			<FeatureImage src={data.creatorImage} alt="Simplate Creator">
				<TextGroup align="center">
					<Text as="h4" textStyle="title.xs">
						A new way to collaborate
					</Text>
					<Text color="altText" textStyle="text.md">
						Select the video's editable fields to create a “Simplate” or simple template. As the admin you can now invite anyone to make changes.
					</Text>
				</TextGroup>
			</FeatureImage>
		</Section>
		<Section>
			<TitleGroup>
				<Text as="h2" gradient="green" textStyle="title.md">
					A centralized collaborative video versioning platform.
				</Text>
				<Text textStyle="text.lg" color="altText">
					Collaborate on templates with your clients directly in Simplate.
				</Text>
			</TitleGroup>
			<FeatureImage src={data.teams} alt="Simplate Team Structure">
				<TextGroup>
					<Text as="h4" textStyle="title.xs">
						Real-time feedback
					</Text>
					<Text color="altText" textStyle="text.md">
						Streamline your review process by leaving trackable feedback for the team members creating the variations.
					</Text>
				</TextGroup>
				<TextGroup>
					<Text as="h4" textStyle="title.xs">
						Globally shared assets
					</Text>
					<Text color="altText" textStyle="text.md">
						Universally share elements with your clients from Simplate's cloud-based asset library.
					</Text>
				</TextGroup>
				<TextGroup>
					<Text as="h4" textStyle="title.xs">
						Flexible team structure
					</Text>
					<Text color="altText" textStyle="text.md">
						Administrators can organize by team, project or campaign. It's up to you.
					</Text>
				</TextGroup>
			</FeatureImage>
		</Section>
		<Section>
			<SplitImageView reverse span={6} src={data.atk}>
				<Box display="flex" alignItems="center">
					<AfterEffectsIcon size={9} />
					<Text marginX={3} as="span" role="img" fontSize={6}>❤️</Text>
					<Logo height={9} />
				</Box>
				<Text marginTop={6} marginBottom={9} as="h2" textStyle="title.md">
					Here's how it works with After Effects
				</Text>
				<TextGroup>
					<Text as="h4" textStyle="title.xs">
						Simplate Animator Toolkit
					</Text>
					<Text color="altText" textStyle="text.md">
						The essential ingredient for video professionals to turn their videos into easily editable Simplates.
					</Text>
				</TextGroup>
			</SplitImageView>
		</Section>
		<Section>
			<TitleGroup span={8}>
				<Text as="h2" gradient="blue" textStyle="title.md">
					Designed for anyone, <br />from art directors to interns.
				</Text>
				<Text textStyle="text.lg" color="altText">
					No experience with complex editing software necessary. <br />
					Remove the complexity.
				</Text>
				<Button color="blue" size="lg">
					<Text>Start creating for free</Text>
				</Button>
			</TitleGroup>
			<Accordion>
				<AccordionItem 
					text="You made it, now sell it! Share Simplates with your clients in your own private marketplace."
					title="Batch Adjust"
					component={<StackedPreviews images={data.stackedPreviews.nodes} />}
				/>
				<AccordionItem 
					text="Auto-populate every video with your brands, logos, fonts and colors."
					title="Auto-Population"
					component={<BrandkitGridIllustration images={data.stackedPreviews.nodes} />}
				/>
				<AccordionItem 
					text="As easy as drag and drop. Swap images, logos, footage and audio to quickly version your videos."
					title="Interactive Modules"
					component={<InteractiveModulesIllustration images={data.stackedPreviews.nodes} />}
				/>
			</Accordion>
		</Section>
		<Section>
			<TitleGroup span={7}>
				<Text as="h2" gradient="orange" textStyle="title.md">
					Give your clients powerful new abilities.
				</Text>
				<Text textStyle="text.lg" color="altText">
					Automatic video generation, live data connections and more with these upcoming features.
				</Text>
				<Button color="orange" size="lg">
					<Text>Start creating for free</Text>
				</Button>
			</TitleGroup>
			<FeatureImage src={data.comingSoon} alt="Coming Soon">
				<TextGroup>
					<Label color="yellow">
						<Text>Coming Soon</Text>
					</Label>
					<Text as="h4" textStyle="title.xs">
						Connect data sources
					</Text>
					<Text color="altText" textStyle="text.md">
						Set up a connection to any data source to drive the content of your Simplate, no matter your expertise.
					</Text>
				</TextGroup>
				<TextGroup>
					<Label color="yellow">
						<Text>Coming Soon</Text>
					</Label>
					<Text as="h4" textStyle="title.xs">
						Live data feeds
					</Text>
					<Text color="altText" textStyle="text.md">
						Automatically feed live data directly into your Simplates. Set event triggers and post near real-time topical videos.
					</Text>
				</TextGroup>
				<TextGroup>
					<Label color="yellow">
						<Text>Coming Soon</Text>
					</Label>
					<Text as="h4" textStyle="title.xs">
						Integrate Social Tools
					</Text>
					<Text color="altText" textStyle="text.md">
						Combine Simplate's automation capabilities with your companies social media management tool for complete automation from population to posting.
					</Text>
				</TextGroup>
			</FeatureImage>
		</Section>
	</Container>
	<BetaCTA 
		src={data.creatorImage} 
	>
		<TitleGroup align="center" offset={2} span={10}>
			<Label color="text"><Text>Start Creating</Text></Label>
			<Text as="h2" textStyle="title.lg">
				Expand your capabilities. <br />
				Empower your clients.
			</Text>
			<Button size="lg">
				<Text gradient="green">Request Beta Access</Text>
			</Button>
		</TitleGroup>
	</BetaCTA>
  </Layout>
);

export const pageQuery = graphql`
	query CreatorsPage {
		creatorImage: file(sourceInstanceName:{eq:"images"}, relativeDirectory:{eq:"app"}, name:{eq:"creator_creators"}) {
			id
			childImageSharp {
				gatsbyImageData(quality: 75, formats: WEBP, placeholder: BLURRED)
			}
		}
		atk: file(sourceInstanceName:{eq:"images"}, relativeDirectory:{eq:"app"}, name:{eq:"atk"}) {
			id
			childImageSharp {
				gatsbyImageData(quality: 75, formats: WEBP, placeholder: BLURRED)
			}
		}
		teams: file(sourceInstanceName:{eq:"images"}, relativeDirectory: {eq: "app"}, name:{eq:"teams"}) {
			id
			childImageSharp {
				gatsbyImageData(quality: 75, formats: WEBP, placeholder: BLURRED)
			}
		}
		stackedPreviews:allFile(filter:{
				sourceInstanceName:{
					eq:"images"
			},
			relativeDirectory:{
					eq:"stacked_previews"
			}
		}) {
			nodes {
				id
				childImageSharp  {
					gatsbyImageData(quality: 50, formats: WEBP)
				}
			}
		}
		comingSoon: file(sourceInstanceName:{eq:"images"}, relativeDirectory:{eq:"coming_soon"}, name:{eq:"coming_soon_creator"}) {
			id
			childImageSharp {
				gatsbyImageData(quality: 75, formats: WEBP, placeholder: BLURRED)
			}
		}
	}
`;
export default CreatorsPage
